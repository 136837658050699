import React, { useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import HeadlineWhite from '../HeadlineWhite/HeadlineWhite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import image1 from '../../images/mockIMG1.png';
import image2 from '../../images/mockIMG2.png';

import styles from './FlipperCard.module.scss';

class FlipperCard extends React.Component {
    constructor(props) {
      super(props);
        this.state = {
        isFlipped: false
      };
      this.handleClick = this.handleClick.bind(this);

    }
  
    handleClick(e) {
      e.preventDefault();
      this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }
  
    render() {
      return (
        <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
          <div className={[styles.frontDiv].join(' ')} onClick={this.handleClick}>
            <div className={styles.imageContainer} style={{backgroundImage: this.state.isFlipped ? '' : "url(" + this.props.frontImage + ")"}}></div>
            {this.state.isFlipped? '' : 
            <div className={styles.contentContainer}>
              <div className={[styles.headline].join(' ')}>
                {this.props.smallH2 &&
                  <h2 className={styles.smallH2}>{this.props.title}</h2>
                }
                {!this.props.smallH2 &&
                  <h2>{this.props.title}</h2>
                }
              </div>
              <div className={styles.centerDiv}>
                <h3 className={styles.flipH3}>{this.props.subtitle}</h3>
              </div>
              {this.props.flipHint && !this.state.isFlipped &&
              <div className={styles.hintDiv}>
                <p className={styles.slidetext}>Klicke auf die Boxen, um mehr zu erfahren.</p>
              </div>
              }
            </div>
    }
          </div>
  
          <div className={[styles.backDiv].join(' ')}>
            <div className={styles.backHeaderDiv}>
              <div className={styles.closeIcon}>
                <FontAwesomeIcon icon={faTimes} onClick={this.handleClick}/>
              </div>
              <div className={styles.backHeadline}>
                
                {this.props.smallH2 &&
                  <h2 className={styles.smallFlippedHeadline}><a id={this.props.title}></a>{this.props.title}</h2>
                }
                {!this.props.smallH2 &&
                  <h2><a id={this.props.title}></a>{this.props.title}</h2>
                }
              </div>
            </div>
            <div>
            {this.props.children}
            </div>
            <div className={styles.upDiv}>
              <a href={'#' + this.props.title} className={styles.cleanA}>
              <FontAwesomeIcon icon={faChevronUp} />
              </a>
            </div>
          </div>
        </ReactCardFlip>
      )
    }
  }

  export default FlipperCard;